import GetStartedComponent from "../components/GetStartedComponent";
import Navbar1 from "../components/Navbar1";

const GetStarted = () => {
  return (
    <div>
      <Navbar1 />
      <GetStartedComponent />
    </div>
  );
};

export default GetStarted;
