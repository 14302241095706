import React from 'react'
import ContactUs from '../components/ContactUs';
import Navbar1 from '../components/Navbar1';

const Contact = () => {
  return (
    <div>
      <Navbar1 />
      <ContactUs />
    </div>
  );
}

export default Contact