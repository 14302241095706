import React from "react";
import {AboutHero} from "../components/aboutHero";
import {AboutDetails} from "../components/aboutDetails";
import Navbar1 from "../components/Navbar1";

const About = () => {
  return (
    <div>
      <Navbar1 />
      <AboutHero />
      <AboutDetails />
    </div>
  );
};

export default About;
