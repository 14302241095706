import React from 'react'
import { Internet } from '../components/Internet'
import Navbar1 from '../components/Navbar1'

const InternetPage = () => {
  return (
    <div>
        <Navbar1 />
        <Internet />
    </div>
  )
}

export default InternetPage