import React from "react";
import {useState} from "react";
// import env from "react-dotenv"
// import {Link} from "react-router-dom";

const GetStartedComponent = () => {
  // form state
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  // const [identity, setIdentity] = useState("");
  // const [idnumber, setIdnumber] = useState("");
  const [plan, setPlan] = useState("");
  const [getstarted, setGetStarted] = useState(false);
  // const [terms, setTerms] = useState("");

  // Submit Events
  const handleSubmit = (e) => {
    const formEle = document.querySelector("form");
    setGetStarted(true);
    e.preventDefault();
    const formData = new FormData(formEle);
    fetch("https://script.google.com/macros/s/AKfycbxBD4jziM0n3JtMu2Gi9LM_xF7x9jJHFcUssuKsduA96ZTU35mZ9ei8u6E1ElKnOFQd/exec",
      {
        method: "POST",
        body: formData,
      }
    ).then((response) => {
      console.log(response);
      // Clearing form fields
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setAddress("");
      // setIdentity("");
      // setIdnumber("");
      setPlan("");
      // setTerms("");
      alert("Your data has been successfully submitted");
    });
  };

  return (
    <div
      onClick={() => setGetStarted(false)}
      className="form flex flex-col justify-center items-center py-5 lg:py-20 px-2 lg:px-20 gap-10 bg-[#f2f2f2]">
      <div className="flex flex-col items-center gap-5">
        <h3 className="about-subheading text-gray-700 text-xl font-semibold ">
          GET STARTED
        </h3>
        <h2 className="text-gray-700 text-center lg:max-w-2xl text-2xl lg:text-4xl font-light">
          Please provide the following information to setup your{" "}
          <span className="text-[#00a85a]">account</span>
        </h2>
      </div>
      <form
        className="form flex justify-center items-start gap-5 flex-col w-4/5 lg:max-w-5xl"
        onSubmit={handleSubmit}>
        <div className="flex justify-center w-full items-center lg:items-start gap-5 flex-col lg:flex-row">
          <input
            type="text"
            className="w-[100%] lg:w-[50%] px-2 py-3 border-[1px] border-gray-300 shadow"
            placeholder="First Name"
            name="First"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            required
          />
          <input
            type="text"
            className="w-[100%] lg:w-[50%] px-2 py-3 border-[1px] border-gray-300 shadow"
            placeholder="Last Name"
            name="Last"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            required
          />
        </div>
        <div className="flex justify-center w-full items-center lg:items-start gap-5 flex-col lg:flex-row">
          <input
            type="email"
            className="w-[100%] lg:w-[50%] px-2 py-3 border-[1px] border-gray-300 shadow"
            placeholder="Email Address"
            name="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
          <input
            type="text"
            className="w-[100%] lg:w-[50%] px-2 py-3 border-[1px] border-gray-300 shadow"
            placeholder="Phone Number"
            name="Phone"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            required
          />
        </div>
        <input
          type="text"
          className=" w-full px-2 py-3 border-[1px] border-gray-300 shadow"
          placeholder="Contact Address (As seen on google map)"
          name="Contact"
          onChange={(e) => setAddress(e.target.value)}
          value={address}
          required
        />
        {/* <div className="flex justify-center w-full items-center lg:items-start gap-5 flex-col lg:flex-row">
          <select
            className=" w-full px-2 py-3 border-[1px] border-gray-300 shadow"
            name="Identity"
            value={identity}
            onChange={(e) => setIdentity(e.target.value)}
            required>
            <option value="">Select a means of Identification</option>
            <option value="NIN">NIN</option>
            <option value="Driver's License">Driver's License</option>
            <option value="International Passport">
              International Passport
            </option>
            <option value="International Passport">
              Voter's card
            </option>
          </select>
          <input
            type="text"
            className="w-[100%] lg:w-[50%] px-2 py-3 border-[1px] border-gray-300 shadow"
            placeholder="Kindly Fill in the ID number here"
            name="IdentityNumber"
            onChange={(e) => setIdnumber(e.target.value)}
            value={idnumber}
            required
          />
        </div> */}
        <select
          className=" w-full px-2 py-3 border-[1px] border-gray-300 shadow"
          name="Plan"
          value={plan}
          onChange={(e) => setPlan(e.target.value)}
          required>
          <option value="">Select plan</option>
          <option value="Connect Lite - #17,500">
            Connect Lite - ₦17,500
          </option>
          <option value="Connect Plus - #25,000">Connect Plus - ₦25,000</option>
          <option value="Connect Pro - #30,000">Connect Pro - ₦30,000</option>
        </select>
      
        {getstarted ? (
          <p
            className="submit flex py-4 px-8 justify-center items-center bg-[#00a85a] hover:bg-green-700 text-white"
            onClick={() => setGetStarted((prev) => !prev)}>
            Your Message has been sent!
          </p>
        ) : (
          <button
            type="submit"
            className="flex py-4 px-8 justify-center items-center bg-[#00a85a]"
            style={{color: "#f5f5f5"}}>
            Submit
          </button>
        )}
      </form>
    </div>
  );
};

export default GetStartedComponent;
