import React from "react";
import { Link } from "react-router-dom";
// import { useState } from "react";
// import Hero1pic from "../assets/images/Hero11-picture.png"

export const Hero = () => {

// const [heroView,setHeroView] = useState("")


  return (
    <div
      id="hero"
      className=" relative h-[600px]  my-0 mx-0 lg:mx-10 pt-0 lg:pt-10 flex justify-center items-center bg-opacity-50"
      style={{
        backgroundImage: `url("https://i.cbc.ca/1.4316966.1506967864!/fileImage/httpImage/image.jpg_gen/derivatives/16x9_780/dv1573354.jpg")`,
      }}>
      <div className="flex justify-center py-10 px-10 items-center gap-4 lg:gap-4 my-2 lg:my-4 flex-col text-center bg-white bg-opacity-80">
        <p className="text-[#00a85a] font-semibold lg:text-xl">
          High Speed Internet and IT Solutions Designed For
        </p>
        <h1 className=" text-gray-900 text-[60px] lg:text-7xl font-bold max-w-4xl ">
          Your Homes And Businesses
        </h1>
        <Link
          className="flex py-2 lg:py-3 text-md lg:px-9 justify-center items-center shadow-md bg-[#00a85a] hover:bg-[#01632c] text-white w-24 lg:w-40"
          to="getstarted">
          Get Started
        </Link>
      </div>
    </div>
  );
};
