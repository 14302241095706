import { Routes, Route } from "react-router-dom";
import "./App.css";
import LandingPage from "./pages/LandingPage";
import About from "./pages/About";
import Services from "./pages/services";
import InternetPage from "./pages/InternetPage";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import GetStarted from "./pages/GetStarted";
import { Footer } from "./components/footer";
import { Footnote } from "./components/footnote";
import ClusterConnect from "./pages/ClusterConnect";
import whatsapp from "./assets/images/whatsapp.png"

function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="about" element={<About />} />
        <Route path="services" element={<Services />} />
        <Route path="internet" element={<InternetPage />} />
        <Route path="clusterConnect" element={<ClusterConnect />} />
        <Route path="contact" element={<Contact />} />
        <Route path="login" element={<Login />} />
        <Route path="getstarted" element={<GetStarted />} />
      </Routes>
      <Footer />
      <Footnote />
      <a className="whatsapp" href="https://wa.me/2349075485627" target="_blank" rel="noreferrer">
        <img src={whatsapp} class="chat-icon" alt="whatsapplink" />
      </a>
    </div>
  );
}

export default App;
