import React from "react";
import {ServicesComponent} from "../components/servicesComponent";
import Navbar1 from "../components/Navbar1";

const Services = () => {
  return (
    <div>
      <Navbar1 />
      <ServicesComponent />
    </div>
  );
};

export default Services;
