import React, {useState} from "react";
import {Link} from "react-router-dom";
import logo from "../assets/images/logo.png";

const Navbar1 = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleHomeMenu, setToggleHomeMenu] = useState(false);
  const [toggleInternetMenu, setToggleInternetMenu] = useState(false);

  return (
    <div className="flex justify-between items-center shadow-md sticky top-0 bg-white z-50">
      <Link
        to="/"
        className="text-xl font-bold text-blue-700 py-2 px-8 hover:bg-[#f5f5f5]">
        <img src={logo} alt="sabi-logo" className="h-12 lg:h-16 lg:ml-16" />
      </Link>

      {/* Desktop menu  */}
      <div className="lg:flex justify-center items-center hidden bg-[#00a85a] mr-20">
        <button
          href="/"
          className="flex justify-center items-center flex-col text-lg text-white font-medium hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 py-2 px-8 relative"
          onClick={() => setToggleHomeMenu((prev) => !prev)}>
          <div className="flex justify-center items-center flex-row z-50">
            <p>Home</p>
            <svg
              class="w-2.5 h-2.5 ms-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6">
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </div>

          {/* Dropdown menu */}
          {toggleHomeMenu && (
            <div className="home_menu home flex flex-col justify-center items-center py-10 text-center shadow-lg absolute top-0 w-32 h-auto z-30 bg-green-600 gap-2">
              <a
                href="/#hero"
                className="hover:bg-green-700 w-full"
                onClick={() => setToggleHomeMenu(false)}>
                Sabi
              </a>
              <a
                href="/#plans"
                className="hover:bg-green-700 w-full"
                onClick={() => setToggleHomeMenu(false)}>
                Plans
              </a>
              <a
                href="/#whysabi"
                className="hover:bg-green-700 w-full"
                onClick={() => setToggleHomeMenu(false)}>
                Why Sabi
              </a>
              <a
                href="/#testimonial"
                className="hover:bg-green-700 w-full"
                onClick={() => setToggleHomeMenu(false)}>
                Testimonials
              </a>
              <a
                href="/#faqs"
                className="hover:bg-green-700 w-full"
                onClick={() => setToggleHomeMenu(false)}>
                FAQs
              </a>
              <a
                href="/#blog"
                className="hover:bg-green-700 w-full"
                onClick={() => setToggleHomeMenu(false)}>
                News
              </a>
            </div>
          )}
        </button>
        <Link
          to="/about"
          className= "text-base text-white font-medium hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 py-2 px-8">
          About
        </Link>
        <Link
          to="/services"
          className= "text-base text-white font-medium hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 py-2 px-8">
          Services
        </Link>
        {/* <Link
          to="/internet"
          className= text-base text-white font-medium hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 py-2 px-8">
          Internet
        </Link> */}
        <div
          className="flex justify-center items-center flex-col text-lg text-white font-medium hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 py-2 px-8 relative cursor-pointer"
          onClick={() => setToggleInternetMenu((prev) => !prev)}>
          <div className="flex justify-center items-center flex-row z-50">
            <p>Internet</p>
            <svg
              class="w-2.5 h-2.5 ms-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6">
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </div>

          {/* Dropdown menu */}
          {toggleInternetMenu && (
            <div className="home_menu home flex flex-col justify-center items-center py-10 text-center shadow-lg absolute top-0 w-32 h-auto z-30 bg-green-600 gap-2">
              <Link
                to="/internet"
                className="hover:bg-green-700 w-full"
                onClick={() => setToggleInternetMenu(false)}>
                Packages
              </Link>
              <Link
                to="/clusterConnect"
                className="hover:bg-green-700 w-full"
                onClick={() => setToggleInternetMenu(false)}>
                ClusterConnect
              </Link>
            </div>
          )}
        </div>
        <Link
          to="/contact"
          className="text-lg text-white font-medium hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 py-2 px-8">
          Contact
        </Link>
        {/* <Link
          to="/login"
          className= "text-base text-white font-medium hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 py-2 px-8">
          Login
        </Link> */}
        <Link
          to="/getstarted"
          className= "text-lg text-white font-medium hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 py-2 px-8">
          Get Started
        </Link>
      </div>

      {/* Mobile menu */}
      <div className="menu_case flex lg:hidden flex-col text-center relative">
        <p
          className="text-lg font-extrabold py-3 pr-5 z-50 cursor-pointer"
          onClick={() => setToggleMenu((prev) => !prev)}>
          {toggleMenu ? "X" : "M"}
        </p>

        {toggleMenu && (
          <div className="menu flex flex-col justify-center items-center py-32 text-center shadow-lg absolute right-0 w-screen h-screen bg-green-600 font-semibold text-white z-30">
            <Link
              to="/"
              className="flex justify-center items-center text-lg hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 w-full h-14"
              onClick={() => setToggleMenu(false)}>
              Home <i className="fas fa-angle-down"></i>
            </Link>
            <Link
              to="/about"
              className="flex justify-center items-center text-lg hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 w-full h-14"
              onClick={() => setToggleMenu(false)}>
              About
            </Link>
            <Link
              to="/services"
              className="flex justify-center items-center text-lg hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 w-full h-14"
              onClick={() => setToggleMenu(false)}>
              Services
            </Link>
            {/* <Link
              to="/internet"
              className="flex justify-center items-center text-lg hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 w-full h-14"
              onClick={() => setToggleMenu(false)}>
              Internet
            </Link> */}
            <div className="flex justify-center items-center text-lg hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 w-full h-14">
              <div
                onClick={() => setToggleInternetMenu((prev) => !prev)}
                className="flex justify-center items-center flex-row">
                <p>Internet</p>
                <svg
                  class="w-2.5 h-2.5 ms-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6">
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </div>

              {/* Dropdown menu */}
              {toggleInternetMenu && (
                <div className="home_internet flex flex-col justify-center items-center py-32 text-center shadow-lg absolute right-0 w-screen h-screen bg-green-600 font-semibold text-white z-30">
                  <Link
                    to="/internet"
                    className="flex justify-center items-center text-lg hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 w-full h-14"
                    onClick={() => setToggleMenu(false)}>
                    Packages
                  </Link>
                  <Link
                    to="/clusterConnect"
                    className="flex justify-center items-center text-lg hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 w-full h-14"
                    onClick={() => setToggleMenu(false)}>
                    ClusterConnect
                  </Link>
                </div>
              )}
            </div>
            <Link
              to="/contact"
              className="flex justify-center items-center text-lg hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 w-full h-14"
              onClick={() => setToggleMenu(false)}>
              Contact
            </Link>
            {/* <Link
              to="/login"
              className="flex justify-center items-center text-lg hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 w-full h-14"
              onClick={() => setToggleMenu(false)}>
              Login
            </Link> */}
            <Link
              to="/getstarted"
              className="flex justify-center items-center text-lg hover:bg-green-700 active:[#ff4500] focus:outline-none focus:ring focus:ring-green-900 w-full h-14"
              onClick={() => setToggleMenu(false)}>
              Get Started
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar1;
