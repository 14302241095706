import React from "react";
import footerLogo from "../assets/images/footer-logo.png";
import location from "../assets/icons/location.svg";
import phone from "../assets/icons/phone.svg";
import email from "../assets/icons/email.svg";
import facebook from "../assets/icons/facebook.svg";
import twitter from "../assets/icons/twitter.svg";
import instagram from "../assets/icons/instagram.svg";
import linkedin from "../assets/icons/linkedin.svg";
import { Link } from "react-router-dom";

export const Footer = () => {
  const menus = [
    {
      id: 1,
      link: "Home",
      route: "/",
    },
    {
      id: 2,
      link: "About",
      route: "about",
    },
    {
      id: 3,
      link: "Services",
      route: "services",
    },
    {
      id: 4,
      link: "Internet",
      route: "internet",
    },
    {
      id: 5,
      link: "Contact",
      route: "contact",
    },
  ];
  // const others = [
  //   {
  //     id: 1,
  //     link: "Careers",
  //     route: "#careers",
  //   },
  //   {
  //     id: 2,
  //     link: "Blogs",
  //     route: "#blogs",
  //   },
  // ];
  const contacts = [
    {
      id: 1,
      detail: "129, Iju Road, Ifako-ijaiye, Lagos",
      contactImg: location,
    },
    {
      id: 2,
      detail: "+234 706 356 1128",
      contactImg: phone,
    },
    {
      id: 3,
      detail: "info@sabinetworks.com",
      contactImg: email,
    },
  ];
  const socials = [
    {
      id: 1,
      socialImg: facebook,
      route: "https://www.facebook.com/sabinetworks/",
    },
    {
      id: 2,
      socialImg: twitter,
      route: "https://twitter.com/sabinetworks",
    },
    {
      id: 3,
      socialImg: instagram,
      route: "https://www.instagram.com/sabinetworks/?hl=en",
    },
    {
      id: 4,
      socialImg: linkedin,
      route: "https://www.linkedin.com/company/sabi-networks-limited/",
    },
  ];

  return (
    <div className="footer flex flex-wrap flex-col-reverse lg:flex-row justify-center lg:justify-between items-center lg:items-start gap-y-5 py-10 lg:py-24 px-3 lg:px-24">
      <Link to="/" className="logo">
        <img src={footerLogo} alt="footer logo" />
      </Link>
      <div className="footer-menus flex flex-wrap justify-center items-center md:items-start px-5 lg:px-0 gap-x-40 gap-y-8 lg:gap-x-10 text-center">
        <div className="footer-sub-menu lg:text-left">
          <p className="footer-menu-sub- text-gray-700 text-base font-semibold mb-2">
            Menu
          </p>
          <ul className="footer- flex flex-col items-start gap-1">
            {menus &&
              menus.map((menu) => {
                return (
                  <li className="flex items-center gap-1">
                    <Link
                      className="footer-menu-link text-gray-700 text-sm font-normal mb-1"
                      to={menu.route}>
                      {menu.link}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>

        {/* <div className="footer-sub-menu">
          <p className="footer-menu-sub- text-gray-700 text-base font-semibold mb-2">
            Others
          </p>
          <ul className="footer-menu flex flex-col items-start gap-1">
            {others &&
              others.map((other) => {
                return (
                  <li className="flex items-center gap-1">
                    <Link
                      className="footer-menu-link text-gray-700 text-sm font-normal mb-1"
                      to={other.route}>
                      {other.link}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div> */}

        <div className="footer-sub-menu lg:text-left">
          <p className="footer-menu-sub- text-gray-700 text-base font-semibold mb-2">
            Contacts
          </p>
          <ul className="footer-menu flex flex-col items-start gap-1">
            {contacts &&
              contacts.map((contact) => {
                return (
                  <li className="flex items-center gap-1">
                    <img src={contact.contactImg} alt="" />
                    <p className="footer-menu-link text-gray-700 text-sm font-normal mb-1">
                      {contact.detail}
                    </p>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <div className="footer-socials flex items-center gap-2 lg:gap-5">
        {socials &&
          socials.map((social) => {
            return (
              <a href={social.route} rel="noreferrer" target={"_blank"}>
                <img
                  src={social.socialImg}
                  className="footer-social"
                  alt="footer"
                />
              </a>
            );
          })}
      </div>
    </div>
  );
};
